import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../UserContext';
import './Navbar.css';

const Navbar = () => {
  const { user } = useUser();
  const [isOpen, setIsOpen] = useState(false);
  const navRef = useRef(null);
  const navigate = useNavigate(); // Initialize useNavigate

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    // Close menu if clicking outside
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        closeMenu();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLinkClick = () => {
    closeMenu();
  };

  // Navigate to login if no user
  useEffect(() => {
    if (!user) {
      navigate('/login'); // Redirect to login page
    }
  }, [user, navigate]); // Depend on user and navigate

  return (
    <nav className="nav-navbar" ref={navRef}>
      <div className="navbar-header">
        <h1 className="logo">MyApp</h1>
        <button className="menu-toggle" onClick={toggleMenu} aria-expanded={isOpen}>
          {isOpen ? '✖' : '☰'}
        </button>
      </div>
      {isOpen && <div className="backdrop" onClick={closeMenu} />}
      <ul className={`nav-list ${isOpen ? 'open' : ''}`}>
        <li>
          <Link to="/" onClick={handleLinkClick}>New Bill</Link>
        </li>
        <li>
          <Link to="/list" onClick={handleLinkClick}>Bills List</Link>
        </li>

        <li>
          <Link to="/camre" onClick={handleLinkClick}>Scan QR</Link>
        </li>
        <li>
          <Link to="/bills/today" onClick={handleLinkClick}>Today Bills List</Link>
        </li>
        {user ? (
          <li className="user-name">Welcome, {user}</li>
        ) : (
          <li>
            <Link to="/login" onClick={handleLinkClick}>Login</Link>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
