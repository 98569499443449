import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import NewBillForm from './NewBillForm'; // Import your NewBillForm component
import styles from './TodayBillsPage.module.css'; // Import the CSS module

const TodayBillsPage = () => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Use navigate to change routes

  useEffect(() => {
    fetchTodayBills(); // Fetch today's bills when the component mounts
  }, []);

  // Function to fetch bills created today
  const fetchTodayBills = () => {
    const today = new Date().toISOString().split('T')[0]; // Get today's date in 'YYYY-MM-DD' format

    fetch(`https://shop.alsayidnavi.com/api/bills/?date=${today}`)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch today’s bills');
        }
        return response.json();
      })
      .then(data => {
        setBills(data.results); // Set the bills for today
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching today’s bills:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  // Function to handle view button click
  const handleViewBill = (id) => {
    navigate(`/bills/${id}`); // Navigate to the bill details page
  };

  if (loading && bills.length === 0) {
    return <p>Loading today's bills...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className={styles.todayBillsContainer}>
      <h1>Bills Added Today</h1>
      <NewBillForm />
      <table className={styles.todayBillsTable}>
        <thead>
          <tr>
            <th>Bill ID</th>
            <th>Date</th>
            <th>Total Price (TL)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {bills.map(bill => (
            <tr key={bill.id}>
              <td>{bill.id}</td>
              <td>{bill.created_at}</td>
              <td>{bill.total_price}</td>
              <td>
                <button
                  onClick={() => handleViewBill(bill.id)}
                  className={styles.billButton} // Apply unique styles
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {bills.length === 0 && (
        <p>No bills were added today.</p>
      )}
    </div>
  );
};

export default TodayBillsPage;
