import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch products from the Django API
        fetch('https://shop.alsayidnavi.com/api/products/')
            .then(response => response.json())
            .then(data => {
                setProducts(data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching products:', error);
                setLoading(false);
            });
    }, []);

    const handleViewDetails = (sku) => {
        // Navigate to the product details page with the product's SKU
        navigate(`/products/${sku}`);
    };

    if (loading) {
        return <div>Loading products...</div>;
    }

    return (
        <div>
            <h1>Product List</h1>
            {products.length === 0 ? (
                <p>No products available.</p>
            ) : (
                <ul>
                    {products.map(product => (
                        <li key={product.id}>
                            <strong>{product.name}</strong> - SKU: {product.sku} - Price: ${product.price}
                            <button onClick={() => handleViewDetails(product.sku)}>View Details</button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default ProductList;
