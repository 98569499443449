import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate to handle navigation
import styles from './BillsPage.module.css'; // Import the CSS module

const BillsPage = () => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const navigate = useNavigate(); // Use navigate to change routes

  useEffect(() => {
    fetchBills('https://shop.alsayidnavi.com/api/bills/');
  }, []);

  // Function to fetch bills from a given URL
  const fetchBills = (url) => {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch bills');
        }
        return response.json();
      })
      .then(data => {
        setBills(prevBills => [...prevBills, ...data.results]);
        setNextPage(data.next); // Set next page URL for pagination
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching bills:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  // Function to handle loading more bills (pagination)
  const loadMoreBills = () => {
    if (nextPage) {
      fetchBills(nextPage); // Fetch next page if available
    }
  };

  // Function to handle view button click
  const handleViewBill = (id) => {
    navigate(`/bills/${id}`); // Navigate to the bill details page
  };

  if (loading && bills.length === 0) {
    return <p>Loading bills...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className={styles.billsContainer}>
      <h1>All Bills</h1>
      <table className={styles.billsTable}>
        <thead>
          <tr>
            <th>Bill ID</th>
            <th>Date</th>
            <th>Total Price (TL)</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {bills.map(bill => (
            <tr key={bill.id}>
              <td>{bill.id}</td>
              <td>{bill.date}</td>
              <td>{bill.total_price}</td>
              <td>
                <button
                  onClick={() => handleViewBill(bill.id)}
                  className={styles.billButton} // Apply unique styles
                >
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {nextPage && (
        <button
          onClick={loadMoreBills}
          disabled={loading}
          className={styles.loadMoreButton} // Apply unique styles
        >
          Load More
        </button>
      )}
      {!nextPage && bills.length > 0 && (
        <p>No more bills to load.</p>
      )}
    </div>
  );
};

export default BillsPage;
