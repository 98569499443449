import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Invoice.css'; // Importing the unique CSS file

const Invoice1 = () => {
  const { id } = useParams(); // Get the invoice ID from the URL parameters
  const navigate = useNavigate();
  const [bill, setBill] = useState(null); // State for bill details
  const [products, setProducts] = useState([]); // State for products associated with the bill
  const [loading, setLoading] = useState(true); // Loading state
  const [message, setMessage] = useState(''); // Message for errors or updates
  const [availableProducts, setAvailableProducts] = useState([]); // State for available products for dropdown

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        // Fetch bill details
        const billResponse = await axios.get(`https://shop.alsayidnavi.com/api/bills/${id}/`);
        setBill(billResponse.data);
        
        // Fetch products for the bill
        const productsResponse = await axios.get(`https://shop.alsayidnavi.com/api/billproducts/?bill=${id}`);
        setProducts(productsResponse.data.results || []); // Set products or empty array if no results

        // Fetch available products for the dropdown
        const productsDropdownResponse = await axios.get('https://shop.alsayidnavi.com/api/product-dropdown/');
        setAvailableProducts(productsDropdownResponse.data); // Set available products for the dropdown
      } catch (error) {
        console.error('Error fetching invoice details:', error);
        setMessage('Error fetching invoice details. Please try again later.'); // Set error message
      } finally {
        setLoading(false); // End loading
      }
    };

    if (id) {
      fetchInvoiceDetails(); // Fetch details if ID is available
    }
  }, [id]);

  const handleProductChange = (index, name, value) => {
    const updatedProducts = [...products];
    updatedProducts[index] = { ...updatedProducts[index], [name]: value };
    setProducts(updatedProducts); // Update state for products
  };

  const handleProductSubmit = async (productId, index) => {
    const product = products[index]; // Get the updated product from the state
    try {
      await axios.put(`https://shop.alsayidnavi.com/api/bill/products/${productId}/`, {
        quantity: product.quantity,
        price: product.price,
      });
      setMessage(`Product ${product.product.name} updated successfully.`);
    } catch (error) {
      console.error('Error updating product:', error);
      setMessage('There was an error updating the product. Please try again.');
    }
  };

  const handleNavigateToPrint = () => {
    navigate(`/bills/print/${id}`);
  };

  if (loading) {
    return <p>Loading invoice details...</p>; // Loading message
  }

  if (!bill) {
    return <p>No bill found with this ID.</p>; // Message if no bill is found
  }

  return (
    <div className="invoice-container"> {/* Use the unique class name */}
      
      <h3>Products</h3>
      {products.length > 0 ? (
        <table className="products-table"> {/* Table for products */}
          <thead>
            <tr>
              <th>Product Name</th>
              <th>Price</th>
              <th>Quantity</th>
              <th>Total</th>
              <th>Actions</th> {/* Add an Actions column for updating */}
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={product.id}>
                {product.product ? (
                  <>
                    <td>{product.product.name}</td>
                    <td>
                      <input
                        type="number"
                        value={product.price}
                        onChange={(e) => handleProductChange(index, 'price', e.target.value)}
                      /> TL
                    </td>
                    <td>
                      <input
                        type="number"
                        value={product.quantity}
                        onChange={(e) => handleProductChange(index, 'quantity', e.target.value)}
                      />
                    </td>
                    <td>{(product.quantity * product.price).toFixed(2)} TL</td>
                    <td>
                      <button onClick={() => handleProductSubmit(product.id, index)}>
                        Update
                      </button>
                    </td>
                  </>
                ) : (
                  <tr>
                    <td colSpan="5">Product information is not available.</td>
                  </tr>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No products found for this invoice.</p>
      )}

      {message && <p style={{ color: 'green' }}>{message}</p>} {/* Success/Error message */}
    </div>
  );
};

export default Invoice1;
