import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const BillProductForm = () => {
    const { billId } = useParams(); // Get the bill ID from the URL
    const [formData, setFormData] = useState({
        bill: billId || '', // Set bill ID from the URL
        product: '',
        quantity: 1,
        price: 0.00,
    });
    const [products, setProducts] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [billDetails, setBillDetails] = useState({}); // State for bill details

    useEffect(() => {
        // Fetch products for the dropdown
        const fetchProducts = async () => {
            try {
                const response = await axios.get('https://shop.alsayidnavi.com/api/product-dropdown/');
                setProducts(response.data);
            } catch (error) {
                console.error("Error fetching products:", error);
                setErrorMessage("Failed to load products.");
            }
        };

        // Fetch bill details based on billId from the URL
        const fetchBillDetails = async () => {
            try {
                const response = await axios.get(`https://shop.alsayidnavi.com/api/bills/${billId}/`); // Adjust URL as needed
                setBillDetails(response.data);
            } catch (error) {
                console.error("Error fetching bill details:", error);
                setErrorMessage("Failed to load bill details.");
            }
        };

        fetchProducts();
        fetchBillDetails();
    }, [billId]); // Fetch on component mount and when billId changes

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://shop.alsayidnavi.com/api/bill-products/kg/', formData);
            setSuccessMessage("Bill product submitted successfully!");
            setErrorMessage('');
            // Reset form
            setFormData({
                bill: billId || '', // Keep the bill ID in the form
                product: '',
                quantity: 1,
                price: 0.00,
            });
        } catch (error) {
            console.error("Error submitting the form:", error);
            setErrorMessage("There was an error submitting the form. Please try again.");
            setSuccessMessage('');
        }
    };

    return (
        <div>
            <h1>New Bill Product</h1>
            
            {/* Bill Header Information */}
            <div style={{ marginBottom: '20px', padding: '10px', border: '1px solid #ccc' }}>
                <h2>Bill Details</h2>
                <p><strong>Bill ID:</strong> {billDetails.id}</p>
                <p><strong>Customer Name:</strong> {billDetails.customer_name}</p> {/* Adjust field names as necessary */}
                <p><strong>Date:</strong> {billDetails.date}</p> {/* Adjust field names as necessary */}
                {/* Add any additional bill information here */}
            </div>

            {/* Bill Product Form */}
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="bill">Bill ID:</label>
                    <input
                        type="number"
                        id="bill"
                        name="bill"
                        value={formData.bill}
                        readOnly // Make it read-only since it's taken from the URL
                    />
                </div>
                <div>
                    <label htmlFor="product">Product:</label>
                    <select
                        id="product"
                        name="product"
                        value={formData.product}
                        onChange={handleChange}
                    >
                        <option value="">Select a product</option>
                        {products.map((product) => (
                            <option key={product.id} value={product.id}>
                                {product.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div>
                    <label htmlFor="quantity">Quantity:</label>
                    <input
                        type="number"
                        id="quantity"
                        name="quantity"
                        value={formData.quantity}
                        onChange={handleChange}
                        min="1"
                    />
                </div>
                <div>
                    <label htmlFor="price">Price:</label>
                    <input
                        type="number"
                        id="price"
                        name="price"
                        value={formData.price}
                        onChange={handleChange}
                        step="0.01"
                    />
                </div>
                <button type="submit">Submit</button>
            </form>
            {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
};

export default BillProductForm;
