import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Invoice.css'; // Importing the unique CSS file
import Invoice1 from './Invoice1';

const Invoice = () => {
  const { id } = useParams(); // Get the invoice ID from the URL parameters
  const navigate = useNavigate();
  const [bill, setBill] = useState(null); // State for bill details
  const [products, setProducts] = useState([]); // State for products associated with the bill
  const [loading, setLoading] = useState(true); // Loading state
  const [message, setMessage] = useState(''); // Message for errors or updates
  const [formData, setFormData] = useState({
    bill: id, // Set the bill ID from the URL
    product: '',
    quantity: 1,
    price: 0.00,
  });
  const [availableProducts, setAvailableProducts] = useState([]); // State for available products for dropdown

  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        // Fetch bill details
        const billResponse = await axios.get(`https://shop.alsayidnavi.com/api/bills/${id}/`);
        setBill(billResponse.data);
        
        // Fetch products for the bill
        const productsResponse = await axios.get(`https://shop.alsayidnavi.com/api/billproducts/?bill=${id}`);
        setProducts(productsResponse.data.results || []); // Set products or empty array if no results

        // Fetch available products for the dropdown
        const productsDropdownResponse = await axios.get('https://shop.alsayidnavi.com/api/product-dropdown/');
        setAvailableProducts(productsDropdownResponse.data); // Set available products for the dropdown
      } catch (error) {
        console.error('Error fetching invoice details:', error);
        setMessage('Error fetching invoice details. Please try again later.'); // Set error message
      } finally {
        setLoading(false); // End loading
      }
    };

    if (id) {
      fetchInvoiceDetails(); // Fetch details if ID is available
    }
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle updating product information directly in the table
  const handleProductChange = (e, index) => {
    const { name, value } = e.target;
    const updatedProducts = [...products];
    updatedProducts[index] = {
      ...updatedProducts[index],
      [name]: value, // Update the specific field (price, quantity)
    };
    setProducts(updatedProducts);
  };

  const handleProductSubmit = async (e, productId) => {
    e.preventDefault();
    const productToUpdate = products.find(product => product.id === productId);
    try {
      await axios.put(`https://shop.alsayidnavi.com/api/bill-products/kg/${productId}/`, productToUpdate);
      setMessage('Product updated successfully!');
    } catch (error) {
      console.error('Error updating product:', error);
      setMessage('Error updating product. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://shop.alsayidnavi.com/api/bill-products/kg/', formData);
      setMessage('Bill product submitted successfully!');
      // Reset form
      setFormData({
        bill: id,
        product: '',
        quantity: 1,
        price: 0.00,
      });
      // Fetch products again to update the list
      const productsResponse = await axios.get(`https://shop.alsayidnavi.com/api/billproducts/?bill=${id}`);
      setProducts(productsResponse.data.results || []); // Update products list
    } catch (error) {
      console.error('Error submitting the form:', error);
      setMessage('There was an error submitting the form. Please try again.');
    }
  };

  const handleNavigateToprint = () => {
    navigate(`/bills/print/${id}`); // Function for navigating to print
  };

  if (loading) {
    return <p>Loading invoice details...</p>; // Loading message
  }

  if (!bill) {
    return <p>No bill found with this ID.</p>; // Message if no bill is found
  }

  return (
    <div className="invoice-container">
      <button onClick={handleNavigateToprint} className="invoice-button">
        Print
      </button>

      <h2>Invoice Details</h2>
      <p>
        <strong>ID:</strong> {bill.id}
      </p>
      <p>
        <strong>Date:</strong> {bill.date}
      </p>
      <p>
        <strong>Total Price:</strong> {bill.total_price} TL
      </p>
      <p>
        <strong>Created At:</strong> {bill.created_at}
      </p>

      <Invoice1/>

      <h3>Add Product to Bill</h3>
      <form className="add-product-form" onSubmit={handleSubmit}>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="product">Product:</label>
            <select
              id="product"
              name="product"
              value={formData.product}
              onChange={handleChange}
            >
              <option value="">Select a product</option>
              {availableProducts.map((product) => (
                <option key={product.id} value={product.id}>
                  {product.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="quantity">Quantity:</label>
            <input
              type="number"
              id="quantity"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
              min="1"
            />
          </div>
        </div>
        <button type="submit">Add Product</button>
      </form>

      {message && <p style={{ color: 'red' }}>{message}</p>} {/* Error message display */}
    </div>
  );
};

export default Invoice;
