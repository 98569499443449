import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Chart from './chart';
const NewBillForm = () => {
  const navigate = useNavigate();
  const [date, setDate] = useState('');

  // Use useEffect to set the date to current date on component mount
  useEffect(() => {
    const currentDate = new Date().toISOString().slice(0, 10); // Format: YYYY-MM-DD
    setDate(currentDate);
  }, []); // Empty dependency array ensures this runs only once on mount

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://shop.alsayidnavi.com/api/bills/', {
        date,
        // Other fields if needed
      });
      console.log('New bill created:', response.data);
      // Redirect to the newly created bill details page (assuming your API returns the new bill's ID)
      navigate(`/bills/${response.data.id}`);
    } catch (error) {
      console.error('Error creating new bill:', error);
    }
  };

  return (
    <div>
      <h2>Create New Bill</h2>
      {/* Render form without displaying the date input */}
      <form onSubmit={handleSubmit}>
        {/* Hidden input to submit the date */}
        <input type="hidden" value={date} onChange={(e) => setDate(e.target.value)} />

        {/* Button to submit the form */}
        <button type="submit">Create Bill</button>
      </form>

      <Chart/>
    </div>
    



  );
};

export default NewBillForm;
