import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './BillDetails.css'; // Import your CSS file

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const BillDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bill, setBill] = useState(null);
  const [products, setProducts] = useState([]);
  const [qrCode, setQrCode] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [skuNotFound, setSkuNotFound] = useState(null); // New state for SKU not found
  const inputRef = useRef(null);

  useEffect(() => {
    const fetchBillDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://shop.alsayidnavi.com/api/bills/${id}/`);
        setBill(response.data);
      } catch (error) {
        console.error('Error fetching bill details:', error);
        setMessage('Error fetching bill details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    const fetchBillProducts = async () => {
      try {
        const response = await axios.get(`https://shop.alsayidnavi.com/api/billproducts/?bill=${id}`);
        setProducts(response.data.results);
      } catch (error) {
        console.error('Error fetching bill products:', error);
        setMessage('Error fetching bill products. Please try again later.');
      }
    };

    if (id) {
      fetchBillDetails();
      fetchBillProducts();

      const intervalId = setInterval(() => {
        fetchBillProducts();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [id]);

  const handleAddProduct = async (qrCodeValue) => {
    try {
      const productResponse = await axios.get(`https://shop.alsayidnavi.com/api/products/?sku=${qrCodeValue}`);
      const product = productResponse.data.results[0];

      if (product) {
        const newProductData = {
          sku: qrCodeValue,
          bill_number: id,
        };

        await axios.post('https://shop.alsayidnavi.com/get/api/bill/new/', newProductData);
        const updatedBillResponse = await axios.get(`https://shop.alsayidnavi.com/api/bills/${id}/`);
        setBill(updatedBillResponse.data);
        setProducts((prevProducts) => [...prevProducts, product]);
        setQrCode('');
        setMessage('Product added successfully!');
        setSkuNotFound(null); // Reset SKU not found state

      } else {
        setMessage('Product not found. Please check the QR code.');
        setSkuNotFound(qrCodeValue); // Set SKU not found state
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(`Error adding product: ${error.response.data.detail || 'An unexpected error occurred.'}`);
      } else {
        setMessage('Error adding product. Please try again.');
      }
    }
  };

  const debouncedAddProduct = debounce(handleAddProduct, 500);

  const handleQrCodeChange = (e) => {
    const value = e.target.value;
    setQrCode(value);
    if (value) {
      debouncedAddProduct(value);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleNavigateToInvoice = () => {
    navigate(`/invoices/${id}`);
  };

  const handleNavigateToqr = () => {
    navigate(`/qr/${id}`);
  };


  
  const handleNavigateToPrint = () => {
    navigate(`/bills/print/${id}`);
  };

  const handleCreateProduct = () => {
    if (skuNotFound) {
      navigate(`/product/create/${skuNotFound}`);
    }
  };

  if (loading) {
    return <p>Loading bill details...</p>;
  }

  return (
    <div className="bill-details-container">
      <header className="bill-header">
        <h1 className="bill-title">Bill Details</h1>
        <button onClick={handleNavigateToInvoice} className="invoice-button">Go to Invoice Details</button>
        <button onClick={handleNavigateToqr} className="qr-button">Go to QR Code</button>
        <button onClick={handleNavigateToPrint} className="invoice-button">Print</button>
        
      </header>
      <div className="bill-info">
        <p><strong>ID:</strong> {bill.id}</p>
        <p><strong>Date:</strong> {bill.date}</p>
        <p><strong>Total Price:</strong> {bill.total_price} TL</p>
        <p><strong>Created At:</strong> {bill.created_at}</p>
      </div>

      <h3>Products</h3>
      <table className="product-table">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>SKU</th>
            <th>Price</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 ? (
            products.map(product => (
              <tr key={product.id}>
                <td>{product.product ? product.product.name : 'N/A'}</td>
                <td>{product.product ? product.product.sku : 'N/A'}</td>
                <td>{product.price}</td>
                <td>{product.quantity}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No products found.</td>
            </tr>
          )}
        </tbody>
      </table>

      <h3>Add New Product by QR Code or SKU</h3>
      <input
        type="text"
        value={qrCode}
        onChange={handleQrCodeChange}
        placeholder="Scan QR code or enter SKU"
        ref={inputRef}
        required
        className="qr-input"
      />

      {message && <p className="error-message">{message}</p>}

      {/* Conditionally render the Create Product button */}
      {skuNotFound && (
        <button onClick={handleCreateProduct} className="create-product-button">
          Create Product with SKU: {skuNotFound}
        </button>
      )}
    </div>
  );
};

export default BillDetails;
