import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './BillsPage.module.css';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import 'chartjs-plugin-zoom'; // Import zoom plugin
import './BillDetails.css';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const BillChart = () => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [nextPage, setNextPage] = useState(null);
  const [zoomEnabled, setZoomEnabled] = useState(false); // State to toggle zoom
  const navigate = useNavigate();

  useEffect(() => {
    fetchBills('https://shop.alsayidnavi.com/api/bills/');
  }, []);

  const fetchBills = (url) => {
    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch bills');
        }
        return response.json();
      })
      .then((data) => {
        setBills((prevBills) => [...prevBills, ...data.results]);
        setNextPage(data.next);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching bills:', error);
        setError(error.message);
        setLoading(false);
      });
  };

  const loadMoreBills = () => {
    if (nextPage) {
      fetchBills(nextPage);
    }
  };

  const handleViewBill = (id) => {
    navigate(`/bills/${id}`);
  };

  // Helper function to calculate daily totals
  const calculateDailyTotals = () => {
    const dailyTotals = {};

    bills.forEach((bill) => {
      const date = bill.date;
      dailyTotals[date] = (dailyTotals[date] || 0) + bill.total_price;
    });

    return {
      labels: Object.keys(dailyTotals),
      datasets: [
        {
          label: 'Total Sales (TL) per Day',
          data: Object.values(dailyTotals),
          fill: false,
          borderColor: 'rgb(75, 192, 192)',
          tension: 0.1,
        },
      ],
    };
  };

  const toggleZoom = () => {
    setZoomEnabled((prev) => !prev);
  };

  if (loading && bills.length === 0) {
    return <p>Loading bills...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className={styles.billsContainer}>
      <h1>All Bills</h1>

      {/* Button to toggle zoom functionality */}
      <button onClick={toggleZoom}>
        {zoomEnabled ? 'Disable Zoom' : 'Enable Zoom'}
      </button>

      {/* Chart to display daily totals */}
      <div className={styles.chartContainer}>
        <h2>Daily Bill Totals</h2>
        <Line
          data={calculateDailyTotals()}
          options={{
            plugins: {
              legend: {
                display: true,
              },
              zoom: {
                pan: {
                  enabled: zoomEnabled, // Use zoomEnabled state for panning
                  mode: 'xy',
                },
                zoom: {
                  enabled: zoomEnabled, // Use zoomEnabled state for zooming
                  mode: 'xy',
                  speed: 10,
                  threshold: 2,
                },
              },
            },
            scales: {
              x: {
                title: {
                  display: true,
                  text: 'Date',
                },
              },
              y: {
                title: {
                  display: true,
                  text: 'Total Price (TL)',
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default BillChart;
