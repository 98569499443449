import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Html5Qrcode } from 'html5-qrcode';
import './BillCamre.css';
import './BillDetails.css';

const BillCamre = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bill, setBill] = useState(null);
  const [products, setProducts] = useState([]);
  const [qrCode, setQrCode] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [skuNotFound, setSkuNotFound] = useState(null);
  const [showScanner, setShowScanner] = useState(false);
  const qrCodeReaderRef = useRef(null);
  const audioRef = useRef(null); // Audio reference
  const [scannedOnce, setScannedOnce] = useState(false); // State to track if scanned

  useEffect(() => {
    const fetchBillDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://shop.alsayidnavi.com/api/bills/${id}/`);
        setBill(response.data);
        console.log('Fetched bill details:', response.data);
      } catch (error) {
        console.error('Error fetching bill details:', error);
        setMessage('Error fetching bill details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    const fetchBillProducts = async () => {
      try {
        const response = await axios.get(`https://shop.alsayidnavi.com/api/billproducts/?bill=${id}`);
        setProducts(response.data.results);
        console.log('Fetched bill products:', response.data.results);
      } catch (error) {
        console.error('Error fetching bill products:', error);
        setMessage('Error fetching bill products. Please try again later.');
      }
    };

    if (id) {
      fetchBillDetails();
      fetchBillProducts();

      const intervalId = setInterval(() => {
        fetchBillProducts();
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [id]);

  const handleAddProduct = async (qrCodeValue) => {
    try {
      // Play sound before asking for confirmation
      if (audioRef.current) {
        await audioRef.current.play().catch((error) => console.error("Audio playback failed", error));
      }

      // Ask user for confirmation before adding the product
      const confirmAdd = window.confirm('Do you want to add this product to the bill?');
      if (!confirmAdd) {
        setMessage('Product addition canceled.');
        return; // Exit if user does not confirm
      }

      // Fetch the product details by QR code (sku)
      const productResponse = await axios.get(`https://shop.alsayidnavi.com/api/products/?sku=${qrCodeValue}`);
      const product = productResponse.data.results[0];

      if (product) {
        const newProductData = {
          sku: qrCodeValue,
          bill_number: id,
        };

        // Post the product to the API
        await axios.post('https://shop.alsayidnavi.com/get/api/bill/new/', newProductData);
        const updatedBillResponse = await axios.get(`https://shop.alsayidnavi.com/api/bills/${id}/`);
        setBill(updatedBillResponse.data);
        setProducts((prevProducts) => [...prevProducts, product]);
        setQrCode('');
        setMessage('Product added successfully!');
        setSkuNotFound(null);

        // Close the scanner after successful scan
        setShowScanner(false); // Hide the scanner
        setScannedOnce(true); // Mark as scanned once

        // Reopen the scanner after five seconds, if needed
        setTimeout(() => {
          setShowScanner(true);
          setScannedOnce(false); // Reset the scanned state
        }, 5000);
      } else {
        setMessage('Product not found. Please check the QR code.');
        setSkuNotFound(qrCodeValue);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setMessage(`Error adding product: ${error.response.data.detail || 'An unexpected error occurred.'}`);
      } else {
        setMessage('Error adding product. Please try again.');
      }
    }
  };

  const startQrCodeScanner = () => {
    const html5QrCode = new Html5Qrcode("qr-reader");
    html5QrCode.start(
      { facingMode: "environment" },
      {
        fps: 10,
        qrbox: 250
      },
      (decodedText) => {
        // Only process the QR code if not scanned yet
        if (!scannedOnce) {
          setQrCode(decodedText);
          handleAddProduct(decodedText); // Process the scanned QR code
        }
      },
      (errorMessage) => {
        // Optional: Handle errors here
      }
    ).catch(err => {
      console.error('Error starting QR code reader:', err);
      setMessage('Error starting QR code reader. Please try again.');
    });
  };

  useEffect(() => {
    if (showScanner) {
      startQrCodeScanner();
    }
    return () => {
      if (showScanner && qrCodeReaderRef.current) {
        qrCodeReaderRef.current.stop();
      }
    };
  }, [showScanner]);

  const handleNavigateToInvoice = () => {
    navigate(`/invoices/${id}`);
  };
  const handleNavigateToMain = () => {
    navigate(`/bills/${id}`);
  };

  const handleNavigateToPrint = () => {
    navigate(`/bills/print/${id}`);
  };

  const handleCreateProduct = () => {
    if (skuNotFound) {
      navigate(`/product/create/${skuNotFound}`);
    }
  };

  if (loading) {
    return <p>Loading bill details...</p>;
  }

  return (
    <div className="bill-details-container">
      <header className="bill-header">
        <h1 className="bill-title">Bill Details</h1>
        

        <button onClick={handleNavigateToMain} className="invoice-button">Main Details</button>

        <button onClick={handleNavigateToInvoice} className="invoice-button">Invoice Details</button>
        <button onClick={handleNavigateToPrint} className="invoice-button">Print</button>
        <button onClick={() => setShowScanner(!showScanner)} className="scan-button">
          {showScanner ? 'Hide QR Code Scanner' : 'Scan QR Code'}
        </button>
      </header>
      {bill && (
        <div className="bill-info">
          <p><strong>ID:</strong> {bill.id}</p>
          <p><strong>Date:</strong> {bill.date}</p>
          <p><strong>Total Price:</strong> {bill.total_price} TL</p>
          <p><strong>Created At:</strong> {bill.created_at}</p>
        </div>
      )}

      {showScanner && (
        <div>
          <h3>QR Code Scanner</h3>
          <div id="qr-reader" ref={qrCodeReaderRef} style={{ width: '100%', height: '300px' }}></div>
          {message && <p className="error-message">{message}</p>}
        </div>
      )}

      <audio ref={audioRef} src="/qr.mp3" preload="auto" />

      <h3>Products</h3>
      <table className="product-table">
        <thead>
          <tr>
            <th>Product Name</th>
            <th>SKU</th>
            <th>Price</th>
            <th>Quantity</th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 ? (
            products.map(product => (
              <tr key={product.id}>
                <td>{product.product ? product.product.name : 'N/A'}</td>
                <td>{product.product ? product.product.sku : 'N/A'}</td>
                <td>{product.price}</td>
                <td>{product.quantity}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No products found.</td>
            </tr>
          )}
        </tbody>
      </table>

      <button onClick={handleCreateProduct} className="create-product-button">Create Product</button>
    </div>
  );
};

export default BillCamre;
