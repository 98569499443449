import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './ProductCreate.css'; // Import the CSS styles

const ProductCreate = () => {
    const { sku } = useParams(); // Get SKU directly from URL parameters
    const navigate = useNavigate();

    // State variables for form fields
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [isKg, setIsKg] = useState(false); // Track if the product is sold by kg

    // State variables for messages
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    // Check if SKU is available
    useEffect(() => {
        if (!sku) {
            setError('SKU is missing in the URL');
        }
    }, [sku]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setMessage('');  // Clear any previous messages
        setError('');    // Clear any previous errors

        // Send a POST request to create the product
        fetch('https://shop.alsayidnavi.com/get/api/product/create/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sku, name, price, quantity, is_kg: isKg }), // Include is_kg in the request
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to create the product');
            }
            return response.json();
        })
        .then(data => {
            // Show success message
            setMessage('Product created successfully!');
            // Navigate to product detail page after creation
            navigate(`/products/${data.sku}`);
        })
        .catch(error => {
            // Show error message
            setError('Error creating product: ' + error.message);
        });
    };

    return (
        <div className="product-create"> {/* Add class here */}
            <h1>Create Product</h1>
            {message && <p className="success-message">{message}</p>}  {/* Success message */}
            {error && <p className="error-message">{error}</p>}  {/* Error message */}
            <form onSubmit={handleSubmit}>
                <div>
                    <label>SKU: {sku}</label>  {/* Display the SKU */}
                </div>
                <div>
                    <label>Name:</label>
                    <input 
                        type="text" 
                        value={name} 
                        onChange={(e) => setName(e.target.value)} 
                        required 
                    />
                </div>
                <div>
                    <label>Price:</label>
                    <input 
                        type="number" 
                        value={price} 
                        onChange={(e) => setPrice(e.target.value)} 
                        required 
                    />
                </div>
                <div>
                    <label>Quantity:</label>
                    <input 
                        type="number" 
                        value={quantity} 
                        onChange={(e) => setQuantity(e.target.value)} 
                        required 
                    />
                </div>
                <div>
                    <label>Is KG:</label>
                    <input 
                        type="checkbox" 
                        checked={isKg} 
                        onChange={(e) => setIsKg(e.target.checked)} 
                    />
                </div>
                <button type="submit">Create Product</button>
            </form>
        </div>
    );
};

export default ProductCreate;
