import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EditBillDetails = () => {
  const { id } = useParams(); // Bill product ID
  const navigate = useNavigate(); // Navigate after successful update
  const [billProduct, setBillProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [formData, setFormData] = useState({
    quantity: '',
    price: '',
  });

  // Fetch bill product details to edit
  useEffect(() => {
    const fetchBillProductDetails = async () => {
      try {
        const response = await axios.get(`https://shop.alsayidnavi.com/api/bill/products/${id}/`);
        setBillProduct(response.data);
        setFormData({
          quantity: response.data.quantity,
          price: response.data.price,
        });
      } catch (error) {
        console.error('Error fetching bill product details:', error);
        setMessage('Error fetching bill product details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBillProductDetails();
    }
  }, [id]);

  // Handle input change
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission to update quantity and price
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await axios.put(`https://shop.alsayidnavi.com/api/bill/products/${id}/`, formData);
      setMessage('Bill product updated successfully.');
      navigate(`/bills/${id}`); // Redirect after successful update
    } catch (error) {
      console.error('Error updating bill product:', error);
      setMessage('Error updating bill product. Please try again.');
    }
  };

  if (loading) {
    return <p>Loading bill product details...</p>;
  }

  if (!billProduct) {
    return <p>No bill product found with this ID.</p>;
  }

  return (
    <div>
      <h2>Edit Bill Product Details</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Quantity:
            <input
              type="number"
              name="quantity"
              value={formData.quantity}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Price (TL):
            <input
              type="number"
              name="price"
              value={formData.price}
              onChange={handleChange}
              required
            />
          </label>
        </div>
        <button type="submit">Update Bill Product</button>
      </form>
      {message && <p style={{ color: message.includes('Error') ? 'red' : 'green' }}>{message}</p>}
    </div>
  );
};

export default EditBillDetails;
