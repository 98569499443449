import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetails.css'; // Import the CSS file

const ProductDetails = () => {
    const { sku } = useParams(); // Get the SKU from the URL
    const [productDetails, setProductDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [isKg, setIsKg] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // New state for editing mode
    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        // Fetch product details by SKU
        fetch(`https://shop.alsayidnavi.com/api/products/${sku}/`)
            .then(response => response.json())
            .then(data => {
                setProductDetails(data);
                setName(data.name);
                setPrice(data.price);
                setQuantity(data.quantity);
                setIsKg(data.is_kg);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching product details:', error);
                setLoading(false);
            });
    }, [sku]);

    const handleUpdateProduct = (e) => {
        e.preventDefault();
        setSuccessMessage('');
        setError('');

        fetch(`https://shop.alsayidnavi.com/api/products/${productDetails.id}/`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ 
                name, 
                sku: productDetails.sku, // Keep SKU unchanged
                price, 
                quantity, 
                is_kg: isKg 
            }),
        })
        .then(response => {
            if (!response.ok) {
                return response.json().then(errorData => {
                    throw new Error(errorData.message || 'Failed to update product');
                });
            }
            return response.json();
        })
        .then(data => {
            setSuccessMessage('Product updated successfully!');
            setProductDetails(data);
            setIsEditing(false); // Exit editing mode after successful update
        })
        .catch(error => {
            console.error('Error updating product:', error);
            setError('Error updating product: ' + error.message);
        });
    };

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        // Reset the fields to the original product details
        setName(productDetails.name);
        setPrice(productDetails.price);
        setQuantity(productDetails.quantity);
        setIsKg(productDetails.is_kg);
    };

    if (loading) {
        return <div className="product-details-container">Loading product details...</div>;
    }

    if (!productDetails) {
        return <div className="product-details-container">Product not found.</div>;
    }

    return (
        <div className="product-details-container">
            <h2>Product Details</h2>
            {successMessage && <div className="success-message">{successMessage}</div>}
            {error && <div className="error-message">{error}</div>}

            {isEditing ? (
                <form onSubmit={handleUpdateProduct}>
                    <div className="product-details">
                        <label>Name:</label>
                        <input 
                            type="text" 
                            value={name} 
                            onChange={e => setName(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="product-details">
                        <label>SKU:</label>
                        <input 
                            type="text" 
                            value={productDetails.sku} 
                            readOnly 
                        />
                    </div>
                    <div className="product-details">
                        <label>Price:</label>
                        <input 
                            type="number" 
                            step="0.01" 
                            value={price} 
                            onChange={e => setPrice(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="product-details">
                        <label>Quantity:</label>
                        <input 
                            type="number" 
                            value={quantity} 
                            onChange={e => setQuantity(e.target.value)} 
                            required 
                        />
                    </div>
                    <div className="product-details">
                        <label>Is measured in KG:</label>
                        <input 
                            type="checkbox" 
                            checked={isKg} 
                            onChange={e => setIsKg(e.target.checked)} 
                        />
                    </div>
                    <button type="submit">Update Product</button>
                    <button type="button" onClick={handleCancelEdit}>Cancel</button>
                </form>
            ) : (
                <div className="product-details">
                    <p><strong>Name:</strong> {productDetails.name}</p>
                    <p><strong>SKU:</strong> {productDetails.sku}</p>
                    <p><strong>Price:</strong> ${productDetails.price}</p>
                    <p><strong>Quantity:</strong> {productDetails.quantity}</p>
                    <p><strong>Is Measured in KG:</strong> {productDetails.is_kg ? 'Yes' : 'No'}</p>
                    <button onClick={handleEditClick}>Edit Product</button>
                </div>
            )}
        </div>
    );
};

export default ProductDetails;
