import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { UserProvider } from './UserContext'; // Import UserProvider
import BillsPage from './components/BillsPage';
import BillDetails from './components/BillDetails';
import NewBillForm from './components/NewBillForm';
import Invoice from './components/Invoice';
import Invoice1 from './components/Invoice1';
import Navbar from './components/Navbar';
import TodayBillsPage from './components/TodayBillsPage';
import BillPrint from './components/BillPrint';
import BillProductForm from './components/BillProductForm';
import EditBillDetails from './components/EditBillDetails';
import Login from './components/login';
import QrScanner from './components/camre';
import ProductList from './components/ProductList';  // The product listing component
import ProductDetail from './components/ProductDetail'; // The product detail component
import ProductCreate from './components/ProductCreate'; // The product detail component
import BillCamre from './components/BillCamre'; // The product detail component


const App = () => {
  return (
    <UserProvider>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/list" element={<BillsPage />} />
          <Route path="/bills/:id" element={<BillDetails />} />
          <Route path="/qr/:id" element={<BillCamre />} />
          <Route path="/" element={<NewBillForm />} />
          <Route path="/invoices/:id" element={<Invoice />} />
          <Route path="/invoices1/:id" element={<Invoice1 />} />
          <Route path="/bills/today" element={<TodayBillsPage />} />
          <Route path="/bills/edit/:id" element={<EditBillDetails />} />
          <Route path="/bills/print/:id" element={<BillPrint />} />
          <Route path="/bills/kg/:id" element={<BillProductForm />} />
          <Route path="/login" element={<Login />} />
          <Route path="/camre" element={<QrScanner />} />

          {/* Updated routes for Product List and Detail */}
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/:sku" element={<ProductDetail />} />  {/* Product detail by SKU */}
          <Route path="/product/create/:sku" element={<ProductCreate />} />  {/* Product create */}
        
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default App;
