import React, { useEffect, useRef, useState } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import { useNavigate } from 'react-router-dom';
import './QrScanner.css';  // Make sure to import the CSS file

const QrScanner = () => {
    const qrReaderRef = useRef(null);
    const [qrCode, setQrCode] = useState('');
    const [cameras, setCameras] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState(null);
    const [html5QrCode, setHtml5QrCode] = useState(null);
    const navigate = useNavigate();  // For navigation

    useEffect(() => {
        // Get available cameras
        Html5Qrcode.getCameras()
            .then(devices => {
                setCameras(devices);
                if (devices.length > 0) {
                    setSelectedCamera(devices[1].id); // Automatically use the second camera
                }
            })
            .catch(err => {
                console.error('Error getting cameras:', err);
            });

        // Cleanup function to stop the scanner when the component unmounts
        return () => {
            if (html5QrCode) {
                html5QrCode.stop().catch(err => {
                    console.error('Error stopping scanning:', err);
                });
            }
        };
    }, [html5QrCode]);

    useEffect(() => {
        if (selectedCamera) {
            startScanning();
        }
    }, [selectedCamera]); // Start scanning when the camera is selected

    const startScanning = () => {
        if (!selectedCamera) {
            alert('Please select a camera.');
            return;
        }

        const qrCodeScanner = new Html5Qrcode(qrReaderRef.current.id);
        setHtml5QrCode(qrCodeScanner);

        qrCodeScanner.start(
            { deviceId: { exact: selectedCamera } },
            { fps: 10, qrbox: 250 },
            onScanSuccess,
            onScanError
        ).catch(err => {
            console.error('Unable to start scanning:', err);
        });
    };

    const onScanSuccess = (decodedText, decodedResult) => {
        setQrCode(decodedText);

        // Send the QR code to the Django API to check if the product exists
        fetch('https://shop.alsayidnavi.com/get/api/product/qr/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ sku: decodedText }),  // Send the QR code as 'sku'
        })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else if (response.status === 404) {
                // Product not found, navigate to the product creation page
                navigate(`/product/create/${decodedText}`);
            } else {
                throw new Error('Unexpected response');
            }
        })
        .then(data => {
            if (data) {
                // Product found, navigate to product detail page
                navigate(`/products/${data.id}`);
            }
        })
        .catch(error => {
            console.error('Error:', error);
        });

        stopScanning();  // Stop scanning after a successful scan
    };

    const onScanError = (errorMessage) => {
        console.warn(`QR code scan error: ${errorMessage}`);
    };

    const stopScanning = () => {
        if (html5QrCode) {
            html5QrCode.stop().catch(err => {
                console.error('Error stopping scanning:', err);
            });
        }
    };

    const swapCamera = () => {
        if (cameras.length > 1) {
            const nextIndex = (cameras.findIndex(camera => camera.id === selectedCamera) + 1) % cameras.length;
            setSelectedCamera(cameras[nextIndex].id);
        }
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <h1>QR Code Scanner</h1>

            <button onClick={swapCamera} disabled={cameras.length <= 1}>
                Swap Camera
            </button>

            <div className="qr-scanner-container">
                <div id="qr-reader" ref={qrReaderRef} className="qr-reader"></div>
                <div className="qr-box"></div> {/* Box for QR code */}
                <div className="red-line"></div> {/* Red line for effect */}
            </div>

            {qrCode && <div style={{ marginTop: '20px' }}>Scanned Result: {qrCode}</div>}
        </div>
    );
};

export default QrScanner;
