import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './BillPrint.css'; // Import your CSS for printing styles

const BillPrint = () => {
  const { id } = useParams();
  const [bill, setBill] = useState(null);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchBillDetails = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`https://shop.alsayidnavi.com/api/bills/${id}/`);
        setBill(response.data);
      } catch (error) {
        console.error('Error fetching bill details:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchBillProducts = async () => {
      try {
        const response = await axios.get(`https://shop.alsayidnavi.com/api/billproducts/?bill=${id}`);
        setProducts(response.data.results);
      } catch (error) {
        console.error('Error fetching bill products:', error);
      }
    };

    if (id) {
      fetchBillDetails();
      fetchBillProducts();
    }
  }, [id]);

  const handlePrint = () => {
    window.print();
  };

  if (loading) {
    return <p>Loading bill details...</p>;
  }

  return (
    <div className="bill-print-container">
      <h1>Bill Details</h1>
      {bill && (
        <div className="bill-info">
          <p><strong>ID:</strong> {bill.id}</p>
          <p><strong>Date:</strong> {bill.date}</p>
          <p><strong>Total Price:</strong> {bill.total_price} TL</p>
          <p><strong>Created At:</strong> {bill.created_at}</p>
        </div>
      )}

      <h3>Products</h3>
      <table className="product-table">
        <thead>
          <tr>
            <th>Product Name</th>
  
            <th>Price</th>
            <th>Quantity</th>
            <th>Total Price</th>
          </tr>
        </thead>
        <tbody>
          {products.length > 0 ? (
            products.map(product => (
              <tr key={product.id}>
                <td>{product.product ? product.product.name : 'N/A'}</td>
            
                <td>{product.price} TL</td>
                <td>{product.quantity}</td>
                <td>{product.quantity*product.price} TL </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4">No products found.</td>
            </tr>
          )}
        </tbody>
      </table>

      <button onClick={handlePrint} className="print-button">Print Bill</button>
    </div>
  );
};

export default BillPrint;
